import React, { useState } from "react"

const SIBFaqEntries = [
  {
    name: "General",
    entries: [
      {
        q: "What versions of the Shakespeare In Bits are available?",
        a: [
          `Shakespeare In Bits is now available exclusively as an online subscription service. 
          Previously, versions of the product were available natively for PC and Mac desktop, 
          iOS, and Android operating systems.  
          Going forward, delivery of the product is being streamlined onto the universal 
          platform that is the internet!`,
        ],
      },
      {
        q: "Why don’t you provide native app or PC versions anymore?",
        a: [
          `These days, software and its distribution use a complex set of interconnected tools 
          and services that rely on third parties and are subject to significant changes with 
          little warning.  We need to be able to regularly and efficiently adjust, rebuild 
          and upgrade our product with minimum disruption to the end-user's experience. 
          The most efficient and economical way to do this is to distribute our product online.`,
        ],
      },
      {
        q: `Who can subscribe to Shakespeare In Bits?`,
        a: [
          `Anybody! But all users fall into one of two broad categories: Single Users  
        and School Users. Single Users are generally users who want to purchase access to 
        the product for their personal (i.e. household) use only - for example, 
        individual students, parents, teachers whose students don't need access, 
        drama students, random Shakespeare geeks etc.`,
          `Schools users are generally teachers, principals, administrators, 
        technology officers etc who want to purchase access for cohorts of students, 
        ranging from a single class to whole districts.`,
        ],
      },
      {
        q: `What do I need to access the product?`,
        a: [
          `The product is completely accessed online so no installation of files to your
         device is needed. An internet connection and a standard browser (e.g. Chrome, 
          Safari, Firefox, Edge) are all that is required.`,
        ],
      },
      {
        q: `How long does a subscription last?`,
        a: [`All subscriptions are for a period of 1 year.`],
      },
      {
        q: `What titles are included in a subscription?`,
        a: [
          `All five titles currently available are included in all subscriptions. 
          They are: Romeo & Juliet, Macbeth, A Midsummer Night's Dream, Hamlet and 
          Julius Caesar.`,
        ],
      },
      {
        q: `Is the website where I access the product safe?`,
        a: [
          `We adhere to up-to-date standards and protocols to ensure that the 
        Shakespeare In Bits product is hosted in a secure server environment. 
        Every effort is taken by us and agents acting on our behalf to eliminate 
        any exposure of our users to bad actors or malicious software.`,
        ],
      },
      {
        q: `If I purchase online, is my financial information secure?`,
        a: [
          `The payment detail pages employ 128 bit SSL technology to ensure
        your details are safe. We use Stripe, a global leader in secure online
        payment services, to process online payments. Shakespeare In Bits does 
        not store your card information, and all private details are treated 
        in accordance with our privacy policy.`,
        ],
      },
      {
        q: `If I bought a previous version of the product for PC or iPad 
        can I transfer the license?`,
        a: [
          `No. The online version is a separate product and not subject to the licensing 
        arrangements entered into for the iPad version or other legacy versions. Making the 
        online version available incurs ongoing registration, hosting, security etc overheads. 
        This is why an annual subscription method of payment needs to be applied. 
        The unit cost however is lower than previous native device licenses.`,
        ],
      },
      {
        q: `Do the plays contain all the text found in a hardcopy edition of a Shakespeare’s play?`,
        a: [
          `Yes. The text of the plays follows standard "modern" (i.e. not First Folio) versions 
        of the plays, that match any standard school text book.`,
        ],
      },
      {
        q: `Why is it called "In Bits"? Is there something missing?`,
        a: [
          `No! The name "In Bits" simply refers to the fact that the play text
        is divided into sections. This is to facilitate presentation of brief 
        sequences of animation, audio and text in manageable chunks that make sense as 
        sub-units of a scene. A minor pun is also intended, given the digital-leaning 
        treatment of the content :)`,
        ],
      },
      {
        q: `How do I purchase a Single-User subscription?`,
        a: [
          `Please first sign up for a free account from the homepage. You can then 
        convert this to a paid account by clicking the 'Buy Now' button on the Pricing page. 
        All Single User subscriptions must be purchased online. 
        All major credit cards are accepted.`,
        ],
      },
    ],
  },
  {
    name: "School Users",
    entries: [
      {
        q: `How do I purchase a subscription for my class or school?`,
        a: [
          `Please first sign up for a free account from the homepage. You then have 2 options:`,
          `1) if you wish to purchase a subscription for 30 students or less, you can purchase a
         Class Account by clicking the Buy Now button on the Pricing page. This subscription must 
         be purchased online. All major credit cards are accepted. 
         Alternative payment methods are accepted for some School subscriptions.`,
          `2) if you wish to purchase a subscription for more than 30 students, please request a 
          School Account quote by clicking the Get a Quote button on the Pricing page. You will
          then receive a follow-up email with a quote for the number of students requested. If
          you are happy to proceed, the subscription may then be purchased online using any 
          major credit card. Alternative payment methods are also accepted for School
          subscriptions.`,
        ],
      },
      {
        q: `Is there a minimum number of users for which I can buy subscriptions?`,
        a: [
          `The school pricing packages are designed for a minimum of 30 users. This is referred 
        to as a Class subscription. You are welcome to purchase a Class subscription if you have
        fewer than 30 students but the price is fixed for this package and payment 
        must be made online.`,
        ],
      },
      {
        q: `How can I get more information on pricing and ordering?`,
        a: [
          `If your query is not answered by the FAQ information on this page, please contact us 
        with details of your query using the 'Contact Us' link at the bottom of this page. `,
        ],
      },
      {
        q: `What payment methods are available?`,
        a: [
          `A single Class subscription must be purchased online. All major credit cards are accepted.  
        Additional payment methods are available for School Subscriptions involving more than 30 students.`,
        ],
      },
      {
        q: `Do you accept purchase orders?`,
        a: [
          `Yes. For School Subscriptions involving more than 30 students we accept purchase orders.`,
        ],
      },
      {
        q: `What countries is the product available in?`,
        a: [
          `Any country in which you can access the Internet! Most of our individual and school 
        users are based in the US, Canada, Australia, the UK, and Ireland. However, we are 
        delighted to have users from locations as geographically diverse as Sudan, Chile and
        Kazakhstan!`,
        ],
      },
      {
        q: `Is the product suitable for BYOD class environments?`,
        a: [
          `Yes. Since the product is now available exclusively online, it is totally platform 
        and device agnostic. So long as you and your students can access the Internet − 
        whether on a PC, laptop, tablet or phone − you can access Shakespeare In Bits!`,
        ],
      },
      {
        q: `Do students get individual log-ins?`,
        a: [
          `No. A single log-in is provided for Class or School subscriptions. The teacher, 
        technology officer or other person administering the account will then recieve 
        a 'School Code' that will allow students and other teachers in the school access to 
        this account. Tracking of individual student usage is up to the school if they 
        wish to do so.`,
        ],
      },
      {
        q: `Can Shakespeare In Bits be used inside a SIS with Single Sign-on capability?`,
        a: [`Yes. We can work with the school or district in question to facilitate this.`],
      }
    ],
  },
]

var toggleEntries = []
SIBFaqEntries.map((section) => {
  return toggleEntries.push(
    section.entries.map((entry) => {
      return { active: false }
    })
  )
})

const SIBFaqPageMain = (props) => {
  return (
    <div className="container">
      <section className="section">
        <div className="columns">
          <div className="column is-8 is-offset-2 has-text-centered">
            <div className="content">
              <h2 className="heading-large-alt">FAQs</h2>
              <p>
                We’re here to help — see below for answers to questions relating
                to Shakespeare In Bits, subscriptions, pricing, using the
                product in a school, data security and more.
              </p>
            </div>
          </div>
        </div>
        <SIBFaqPageSection />
      </section>
    </div>
  )
}

const SIBFaqPageSection = () => {
  const [accordionToggles, setAccordionToggles] = useState(toggleEntries)

  const toggleActive = (e, sectionIndex, entryIndex) => {
    e.preventDefault()
    var changeEntries = toggleEntries
    changeEntries[sectionIndex][entryIndex].active =
      !changeEntries[sectionIndex][entryIndex].active
    setAccordionToggles({ ...changeEntries })
  }

  return (
    <div className="columns">
      <div className="column is-8 is-offset-2">
        {SIBFaqEntries.map((section, sectionIndex) => {
          return (
            <div className="accordion" key={`section_${sectionIndex}`}>
              <h3>{section.name}</h3>
              <ul>
                {section.entries.map((entry, entryIndex) => {
                  return (
                    <li
                      role="presentation"
                      className={`${
                        accordionToggles[sectionIndex][entryIndex].active
                          ? "active"
                          : ""
                      }`}
                      key={`section_${sectionIndex}_entry_${entryIndex}`}
                      onClick={(e) => toggleActive(e, sectionIndex, entryIndex)}
                      onKeyDown={(e) =>
                        toggleActive(e, sectionIndex, entryIndex)
                      }
                    >
                      <h4>{entry.q}</h4>
                      <div>
                        {entry.a.map((para, paraIndex) => {
                          return (
                            <p
                              key={`section_${sectionIndex}_entry_${entryIndex}_para_${paraIndex}`}
                            >
                              {para}
                            </p>
                          )
                        })}
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SIBFaqPageMain
