import React from "react"

const SIBFaqPageIntro = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Need help?</h1>
              <p>Advice and answers from the Shakespeare In Bits Team</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBFaqPageIntro
