import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

import SIBFaqPageIntro from "../components/frontend/faqpage/intro"
import SIBFaqPageMain from "../components/frontend/faqpage/faqmain"
import SIBFaqPageProductSupport from "../components/frontend/faqpage/support"

const FAQPage = ({ data }) => {
  return (
    <Layout showFrontendLinks={true}>
      <Seo title="Shakespeare In Bits - FAQ" />
      <SIBFaqPageIntro/>
      <SIBFaqPageMain/>
      <SIBFaqPageProductSupport/>
    </Layout>
  )
}

export default FAQPage